/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'Accordion',
        import() {
            return import('@stories/Widgets/Accordion/Accordion');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    /* Account Area */
    {
        name: 'AccountHeader',
        import() {
            return import('@stories/Widgets/Account/AccountHeader/AccountHeader');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AccountHelpCentre',
        import() {
            return import('@stories/Widgets/Account/AccountHelpCentre/AccountHelpCentre');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AccountOrderLines',
        import() {
            return import('@stories/Widgets/Account/AccountOrderLines/AccountOrderLines');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AccountOrderList',
        import() {
            return import('@stories/Widgets/Account/AccountOrderList/AccountOrderList');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ConfirmNewPassword',
        import() {
            return import('@stories/Widgets/Account/ConfirmNewPassword/ConfirmNewPassword');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EmbeddedForm',
        import() {
            return import('@stories/Widgets/Forms/EmbeddedForm/EmbeddedForm');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ForgottenPassword',
        import() {
            return import('@stories/Widgets/Account/ForgottenPassword/ForgottenPassword');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Login',
        import() {
            return import('@stories/Widgets/Account/Login/Login');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ModalBreakdown',
        import() {
            return import('@stories/Widgets/Account/ModalBreakdown/ModalBreakdown');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MyDetailsForm',
        import() {
            return import('@stories/Widgets/Account/MyDetailsForm/MyDetailsForm');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HoldingPageMessage',
        import() {
            return import('@stories/Widgets/Account/HoldingPageMessage/HoldingPageMessage');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TwoFactorPopup',
        import() {
            return import('@stories/Widgets/TwoFactorPopup/TwoFactorPopup');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    /* Account Area - End */
    {
        name: 'ArticleDetailPageHeroBanner',
        import() {
            return import('@stories/Widgets/ArticleDetailPageHeroBanner/ArticleDetailPageHeroBanner');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BedroomStorageCardsList',
        import() {
            return import('@stories/Widgets/BedroomStorageCardsList/BedroomStorageCardsList');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BespokeAccessorySelector',
        import() {
            return import('@stories/Widgets/BespokeAccessorySelector/BespokeAccessorySelector');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BlogListing',
        import() {
            return import('@stories/Widgets/BlogListing/BlogListing');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContentCardList',
        import() {
            return import('@stories/Widgets/ContentCardList/ContentCardList');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContentCarousel',
        import() {
            return import('@stories/Widgets/ContentCarousel/ContentCarousel');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CtaSeparator',
        import() {
            return import('@stories/Widgets/CtaSeparator/CtaSeparator');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EmbeddedPdfLink',
        import() {
            return import('@stories/Widgets/EmbeddedPdfLink/EmbeddedPdfLink');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FeaturedBlogArticles',
        import() {
            return import('@stories/Widgets/FeaturedBlogArticles/FeaturedBlogArticles');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FeaturedRealRooms',
        import() {
            return import('@stories/Widgets/FeaturedRealRooms/FeaturedRealRooms');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FinanceCalculator',
        import() {
            return import('@stories/Widgets/FinanceCalculator/FinanceCalculator');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FindNearestShowroom',
        import() {
            return import('@stories/Widgets/FindNearestShowroom/FindNearestShowroom');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FloatingCta',
        import() {
            return import('@stories/Widgets/FloatingCta/FloatingCta');
        },
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Footer',
        import() {
            return import('@stories/Widgets/Footer/Footer');
        },
        config: {
            hydrate: 'always',
        },
    },
    /* Forms */
    {
        name: 'AccountContactForm',
        import() {
            return import('@stories/Widgets/Forms/AccountContactForm/AccountContactForm');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BookAFreeDesignVisitForm',
        import() {
            return import('@stories/Widgets/Forms/BookAFreeDesignVisitForm/BookAFreeDesignVisitForm');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContactForm',
        import() {
            return import('@stories/Widgets/Forms/ContactForm/ContactForm');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RequestAFreeBrochureForm',
        import() {
            return import('@stories/Widgets/Forms/RequestAFreeBrochureForm/RequestAFreeBrochureForm');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BrochureRequested',
        import() {
            return import('@stories/Widgets/BrochureRequested/BrochureRequested');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    /* Forms - End */
    {
        name: 'GatedContentForm',
        import() {
            return import('@stories/Widgets/GatedContentForm/GatedContentForm');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Header',
        import() {
            return import('@stories/Widgets/Header/Header');
        },
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'HomepageHeroBannerVideo',
        import() {
            return import('@stories/Widgets/HomepageHeroBannerVideo/HomepageHeroBannerVideo');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HtmlSiteMap',
        import() {
            return import('@stories/Widgets/HtmlSiteMap/HtmlSiteMap');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'IconCardsCarousel',
        import() {
            return import('@stories/Widgets/IconCardsCarousel/IconCardsCarousel');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'IconCardsListing',
        import() {
            return import('@stories/Widgets/IconCardsListing/IconCardsListing');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'IconCtaBanner',
        import() {
            return import('@stories/Widgets/IconCtaBanner/IconCtaBanner');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InnerPageHeroBanner',
        import() {
            return import('@stories/Widgets/InnerPageHeroBanner/InnerPageHeroBanner');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Issuelist',
        import() {
            return import('@stories/Widgets/Issuelist/Issuelist');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LocationMap',
        import() {
            return import('@stories/Widgets/LocationMap/LocationMap');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MediaCollage',
        import() {
            return import('@stories/Widgets/MediaCollage/MediaCollage');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'OptionsCarousel',
        import() {
            return import('@stories/Widgets/OptionsCarousel/OptionsCarousel');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PaymentForm',
        import() {
            return import('@stories/Widgets/PaymentForm/PaymentForm');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PinboardItemList',
        import() {
            return import('@stories/Widgets/PinboardItemList/PinboardItemList');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PinboardList',
        import() {
            return import('@stories/Widgets/PinboardList/PinboardList');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PostPaymentMessage',
        import() {
            return import('@stories/Widgets/PostPaymentMessage/PostPaymentMessage');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'QuotesList',
        import() {
            return import('@stories/Widgets/QuotesList/QuotesList');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'QuotePaymentTypes',
        import() {
            return import('@stories/Widgets/QuotePaymentTypes/QuotePaymentTypes');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RangeCardsCarousel',
        import() {
            return import('@stories/Widgets/RangeCardsCarousel/RangeCardsCarousel');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RangeDetailGallery',
        import() {
            return import('@stories/Widgets/RangeDetailGallery/RangeDetailGallery');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RangeCardsOffersCarousel',
        import() {
            return import('@stories/Widgets/RangeCardsOffersCarousel/RangeCardsOffersCarousel');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RangeListing',
        import() {
            return import('@stories/Widgets/RangeListing/RangeListing');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RealRoomsListing',
        import() {
            return import('@stories/Widgets/RealRoomsListing/RealRoomsListing');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ReducedFooter',
        import() {
            return import('@stories/Widgets/ReducedFooter/ReducedFooter');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RichText',
        import() {
            return import('@stories/Widgets/RichText/RichText');
        },
        config: {
            // @note: currently no javascript functionality after rendering
            hydrate: 'never',
        },
    },
    {
        name: 'RoomCardsCarousel',
        import() {
            return import('@stories/Widgets/RoomCardsCarousel/RoomCardsCarousel');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SearchResultsList',
        import() {
            return import('@stories/Widgets/SearchResultsList/SearchResultsList');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ShowroomDetailsMap',
        import() {
            return import('@stories/Widgets/ShowroomDetailsMap/ShowroomDetailsMap');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ShowroomDirectory',
        import() {
            return import('@stories/Widgets/ShowroomDirectory/ShowroomDirectory');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SignpostCardsSection',
        import() {
            return import('@stories/Widgets/SignpostCardsSection/SignpostCardsSection');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContent',
        import() {
            return import('@stories/Widgets/SplitContent/SplitContent');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitSignpostCards',
        import() {
            return import('@stories/Widgets/SplitSignpostCards/SplitSignpostCards');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TabbedHotspot',
        import() {
            return import('@stories/Widgets/TabbedHotspot/TabbedHotspot');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TestimonialCarousel',
        import() {
            return import('@stories/Widgets/ContentCarousel/Testimonial/Testimonial');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TrustpilotCarousel',
        import() {
            return import('@stories/Widgets/TrustpilotCarousel/TrustpilotCarousel');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TrustpilotSummary',
        import() {
            return import('@stories/Widgets/TrustpilotSummary/TrustpilotSummary');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TrustpilotSingleCarousel',
        import() {
            return import('@stories/Widgets/TrustpilotSingleCarousel/TrustpilotSingleCarousel');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CookieDeclaration',
        import() {
            return import('@stories/Widgets/CookieDeclaration/CookieDeclaration');
        },
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'TrustpilotRating',
        import() {
            return import('@stories/Widgets/TrustpilotRating/TrustpilotRating');
        },
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'SquareSignposts',
        import() {
            return import('@stories/Widgets/SquareSignposts/SquareSignposts');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FeatureSpotlight',
        import() {
            return import('@stories/Widgets/FeatureSpotlight/FeatureSpotlight');
        },
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'SplitImages',
        import() {
            return import('@stories/Widgets/SplitImages/SplitImages');
        },
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'Timeline',
        import() {
            return import('@stories/Widgets/Timeline/Timeline');
        },
        config: {
            hydrate: 'never',
        },
    },
    /* Components */
    {
        name: 'Breadcrumb',
        import() {
            return import('@stories/Components/Breadcrumb/Breadcrumb');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MediaEmbed',
        import() {
            return import('@stories/Components/Misc/MediaEmbed/MediaEmbed');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SaleBanner',
        import() {
            return import('@stories/Components/SalesBanner/SalesBanner');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SectionDivider',
        import() {
            return import('@stories/Components/SectionDivider/SectionDivider');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NearestStore',
        import() {
            return import('@stories/Widgets/NearestStore/NearestStore');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PinboardActionDrawer',
        import() {
            return import('@stories/Components/PinboardActionDrawer/PinboardActionDrawer');
        },
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Video',
        import() {
            return import('@stories/Widgets/Video/Video');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'WideIconCtaBanner',
        import() {
            return import('@stories/Widgets/WideIconCtaBanner/WideIconCtaBanner');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VisitConfirmed',
        import() {
            return import('@stories/Widgets/VisitConfirmed/VisitConfirmed');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PopUp',
        import() {
            return import('@stories/Widgets/PopUp/PopUp');
        },
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'MediaGalleryWidget',
        import() {
            return import('@stories/Widgets/MediaGalleryWidget/MediaGalleryWidget');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PriceComparison',
        import() {
            return import('@stories/Widgets/PriceComparison/PriceComparison');
        },
        config: {
            hydrate: 'in-view',
        },
    },
    /* Components - End */
];
